import {
  ConnectionQuality,
  LocalParticipant,
  Room,
  RoomEvent,
  RoomOptions,
  VideoPresets,
} from "livekit-client";
import {
  RecordingServiceOptions,
  RecordingService,
} from "../common/hooks/useRecorder";
import { livekitWSUrl } from "../config/constants";
import { handleError } from "../helpers/sentry";

export class LivekitService implements RecordingService {
  private options: RecordingServiceOptions = {};
  public livekitRoom;
  public videoTrack = null;

  async connect(options: RecordingServiceOptions) {
    this.options = options;
    try {
      const roomOpts: RoomOptions = {
        publishDefaults: {
          videoCodec: "vp9",
          backupCodec: {
            codec: "vp8",
          },
          videoSimulcastLayers: [VideoPresets.h360, VideoPresets.h540],
        },
        videoCaptureDefaults: {
          resolution: VideoPresets.h540.resolution,
        },
      };

      const room = new Room(roomOpts);
      await room.connect(livekitWSUrl, options.streamingToken, {
        autoSubscribe: false,
      });

      await room.localParticipant.setCameraEnabled(true);
      await room.localParticipant.setMicrophoneEnabled(false);

      room.on(RoomEvent.Disconnected, (error) => {
        handleError(error);
        this.options.onConnectionFailure();
      });
      
      room.on(RoomEvent.ConnectionQualityChanged, () => {
        const isGoodQuality = this.handleNetworkQualityChange(room);
        options.onNetworkQualityChange(isGoodQuality);
      });

      this.livekitRoom = room;
    } catch (error) {
      handleError(error);
      options.onConnectionFailure(error);
    }
  }

  previewElement(videoRef) {
    const localParticipant: LocalParticipant =
      this.livekitRoom.localParticipant;
    const localVideoTrack = Array.from(localParticipant.videoTracks.values())[0]
      .track;
    if (videoRef.current && localVideoTrack) {
      this.videoTrack = localVideoTrack;
      this.videoTrack.attach(videoRef.current);
      this.videoTrack.on("stopped", this.handleTrackStopped);
    }
  }

  handleNetworkQualityChange = (room) => {
    return (
      room.localParticipant.connectionQuality === ConnectionQuality.Good ||
      room.localParticipant.connectionQuality === ConnectionQuality.Excellent
    );
  };

  disconnect(): void {
    if (this.livekitRoom) {
      this.livekitRoom?.disconnect();
      if (this.videoTrack) {
        this.videoTrack?.stop();
      }
    }
  }

  private handleTrackStopped = () => {
    if (this.livekitRoom) {
      this.livekitRoom.disconnect();
      this.options.onTrackChange();
    }
  };
}
